
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Inhibitor } from "../../models/reports/inhibitor";
import { formatDecimal } from '../../utils/format';

export default Vue.extend({
  name: "FactorVIIIUsage",
  data: () => ({
    dataTable: [] as Inhibitor[],
    isLoading: true,
    format: (x: number) => formatDecimal(x, 6),
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    ranks() {
      return [
        {
          label: "H",
          key: "h",
        },
        {
          label: "UM",
          key: "uM",
        },
        {
          label: "LM",
          key: "lM",
        },
        {
          label: "L",
          key: "l",
        },
      ];
    },
    headers() {
      return [
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.agsreports.FVIIIUsagebyGNI.rank"),
          value: "",
        },
        {
          text: i18n.t("reports.agsreports.FVIIIUsagebyGNI.mean"),
          value: "",
        },
        {
          text: i18n.t("reports.agsreports.FVIIIUsagebyGNI.median"),
          value: "",
        },
        {
          text: i18n.t("reports.agsreports.FVIIIUsagebyGNI.countries"),
          value: "totalConsumption",
        },
      ];
    },
  },
  methods: {
    getData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/TreatmentStatisticsByGNI/1`, {
          params: { year: year },
        })
        .then((res) => {
          this.dataTable = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/wfh/TreatmentStatisticsByGNI/1/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.FactorVIIIUsage.title")}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getData(this.selectedSurvey.year);
    },
  },
});
